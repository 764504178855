import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f88687a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "relative inline-block text-left text-500 1",
  ref: "dropdown",
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.clicked && $setup.clicked(...args))),
      class: "select-b-box"
    }, [
      _renderSlot(_ctx.$slots, "select", {}, undefined, true)
    ]),
    _createVNode(_Transition, {
      "enter-active-class": "transition ease-out duration-100",
      "enter-class": "transform opacity-0 scale-95",
      "enter-to-class": "transform opacity-100 scale-100",
      "leave-active-class": "transition ease-in duration-75",
      "leave-class": "transform opacity-100 scale-100",
      "leave-to-class": "transform opacity-0 scale-95"
    }, {
      default: _withCtx(() => [
        ($setup.isMenuOpen)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["absolute mt-2 rounded-md shadow-lg text-sm border z-20 overflow-y-scroll menu-select", [$props.align == 'right' && $setup.psValueHolder.languageCode != 'ar' ? 'origin-top-right right-0' : 'origin-top-left left-0', $props.h]]),
              style: {"min-height":"auto !important","width":"100%","background-color":"#000"}
            }, [
              _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "filter", {}, undefined, true),
                _createElementVNode("div", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.isMenuOpen = !$setup.isMenuOpen))
                }, [
                  _renderSlot(_ctx.$slots, "list", {}, undefined, true)
                ]),
                _renderSlot(_ctx.$slots, "loadmore", {}, undefined, true)
              ])
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 512))
}